<template>
  <div id="dropdownHover">
    <div  class="dropdown">
      <button class="dropbtn">Dropdown</button>
      <div class="dropdown-content">
        <a href="#">Link 1</a>
        <a href="#">Link 2</a>
        <a href="#">Link 3</a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:"DropdownHover"
};
</script>